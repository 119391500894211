.CheckBox {
    width: 100%;
    display: flex;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.CheckBox > input {
    margin-right: 10px;
}

.CheckBox > label {
    font-weight: bold;
}