.Card > p {
    text-align: center;
    align-self: center;
    font-weight: 300;
    font-size: 14px;
}

#resend {
    font-size: 14px;
    font-weight: 800;
}