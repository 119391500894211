.DropdownMenu {
  align-self: center;
  margin: 20px;
}

.DropdownMenu > button {
  background: none;
  color: white;
  border: none;
  font-size: x-large;
  font-weight: bold;
  margin: 10px;
}

.DropdownMenu > .DropdownContent :hover {
    text-decoration: underline;
}

.DropdownMenu > .DropdownContent {
  cursor: pointer;
  width: 20%;
  left: 79%;
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #e91a2b;
  font-size: x-large;
  font-weight: bold;
  padding: 10px;
  color: white;
  border: black;
  border-style: solid;
}

.DropdownMenu > .DropdownContent > * {
  width: 100%;
  color: white;
  padding: 5px;
  transition: color 1s;
}

.DropdownMenu > .DropdownContent > * :hover {
  color: black;
}
