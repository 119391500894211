.Button {
    font-family: 'Inter', sans-serif;
    background-color: #2B2C47;
    border: none;
    color: white;
    font-size: large;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 40px;
    margin-bottom: 20px;
    align-self: center;
    transition: 0.2s;
}

.Button:hover {
    background-color: #474972;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}