.Card {
    background-color: white;
    width: 500px;
    border-radius: 10px;
    border-style: solid;
    padding: 25px;
    padding-left: 50px;
    padding-right: 50px;
    margin: auto;
    border-width: 1px;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-color: grey;
}

.CardDescription {
    margin: 10px;
    font-weight: bold;
    text-align: center;
    align-self: center;
}

.Card > h1 {
    width: 70%;
    text-align: center;
    align-self: center;
    font-weight: bolder;
}

.Card > .CardError {
    color: red;
    font-weight: bold;
}

.Card > * > a {
    text-align: center;
    align-self: center;
    font-weight: 300;
    font-size: 12px;
}

.Card > .Button {
    margin-bottom: 10px;
}

.Card > form {
    width: 100%;
    height: 100%;
}