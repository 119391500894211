@import url(https://fonts.googleapis.com/css2?family=Inter:wght@700&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
body {
  margin: 0;
  height: 100%;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EEF2F7;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
    height: 100%;
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

a {
    text-decoration: none;
    color: black;
}

a:hover {
    text-decoration: underline;
    color: purple;
}

form {
    display: flex;
    flex-direction: column;
}
.DropdownMenu {
  align-self: center;
  margin: 20px;
}

.DropdownMenu > button {
  background: none;
  color: white;
  border: none;
  font-size: x-large;
  font-weight: bold;
  margin: 10px;
}

.DropdownMenu > .DropdownContent :hover {
    text-decoration: underline;
}

.DropdownMenu > .DropdownContent {
  cursor: pointer;
  width: 20%;
  left: 79%;
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #e91a2b;
  font-size: x-large;
  font-weight: bold;
  padding: 10px;
  color: white;
  border: black;
  border-style: solid;
}

.DropdownMenu > .DropdownContent > * {
  width: 100%;
  color: white;
  padding: 5px;
  transition: color 1s;
}

.DropdownMenu > .DropdownContent > * :hover {
  color: black;
}

.Header {
  width: 100%;
  height: 180px;
  background: #e91a2b;
}

.Header > .NavBar {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NavLink {
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
    font-size: 20px;
    color: white;
}

.SelectedNavLink {
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
    font-size: 20px;
    text-decoration: underline;
    color: blue;
}

.Header > .NavBar > span {
  color: white;
  font-size: x-large;
  font-weight: bold;
  width: 200px;
  text-align: center;
}

.Header > .NavBar > img {
  position: absolute;
  left: 30px;
  margin-top: auto;
  margin-bottom: auto;
}

.LoggedHeader > .LoggedNavBar > p {
  color: white;
  font-size: x-large;
  font-weight: bold;
  margin-right: auto;
  margin-left: 20px;
}

.LoggedHeader {
  width: 100%;
  height: 100px;
  background: #e91a2b;
}

.LoggedHeader > .LoggedNavBar {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.LoggedHeader > .LoggedNavBar > img {
  margin-left: 10px;
  justify-self: start;
  height: 70px;
}

.TextField {
    padding-left: 25px;
    padding-right: 25px;
}

.TextField > input {
    height: 35px;
    width: 100%;
    border-style: solid;
    border-color: darkgrey;
    border-width: 1px;
    border-radius: 5px;
    font-size: 18px;
    box-sizing: border-box;
}

.TextField > label {
    display: block;
    margin-bottom: 4px;
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
}

.TextField > p {
    margin-top: 0px;
    font-size: 14px;
    color: red;
}
.Card {
    background-color: white;
    width: 500px;
    border-radius: 10px;
    border-style: solid;
    padding: 25px;
    padding-left: 50px;
    padding-right: 50px;
    margin: auto;
    border-width: 1px;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-color: grey;
}

.CardDescription {
    margin: 10px;
    font-weight: bold;
    text-align: center;
    align-self: center;
}

.Card > h1 {
    width: 70%;
    text-align: center;
    align-self: center;
    font-weight: bolder;
}

.Card > .CardError {
    color: red;
    font-weight: bold;
}

.Card > * > a {
    text-align: center;
    align-self: center;
    font-weight: 300;
    font-size: 12px;
}

.Card > .Button {
    margin-bottom: 10px;
}

.Card > form {
    width: 100%;
    height: 100%;
}
.Button {
    font-family: 'Inter', sans-serif;
    background-color: #2B2C47;
    border: none;
    color: white;
    font-size: large;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 40px;
    margin-bottom: 20px;
    align-self: center;
    transition: 0.2s;
}

.Button:hover {
    background-color: #474972;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.Card > p {
    text-align: center;
    align-self: center;
    font-weight: 300;
    font-size: 14px;
}

#resend {
    font-size: 14px;
    font-weight: 800;
}

.LinkButton {
  background: none!important;
  border: none;
  padding: 0!important;
  color: grey;
  font-size: small;
  text-decoration: none;
  cursor: pointer;
  align-self: center;
}

.LinkButton:hover {
    text-decoration: underline;
    color: purple;
}
.Page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.LinkButton {
  background: none!important;
  border: none;
  padding: 0!important;
  color: grey;
  font-size: small;
  text-decoration: none;
  cursor: pointer;
  align-self: center;
}

.LinkButton:hover {
    text-decoration: underline;
    color: purple;
}
.CheckBox {
    width: 100%;
    display: flex;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.CheckBox > input {
    margin-right: 10px;
}

.CheckBox > label {
    font-weight: bold;
}
.SelectField {
  margin-left: 25px;
  margin-right: 25px;
}

.SelectField > select {
  height: 35px;
  width: 80%;
  border-style: solid;
  border-color: darkgrey;
  border-width: 1px;
  border-radius: 5px;
  font-size: 18px;
}

.SelectField > select > option {
  height: 35px;
  width: 80%;
  border-style: solid;
  border-color: darkgrey;
  border-width: 1px;
  border-radius: 5px;
  font-size: 18px;
}

.SelectField > label {
  display: block;
  margin-bottom: 4px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.SelectField > p {
  margin-top: 0px;
  font-size: 14px;
  color: red;
}

