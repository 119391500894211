.SelectField {
  margin-left: 25px;
  margin-right: 25px;
}

.SelectField > select {
  height: 35px;
  width: 80%;
  border-style: solid;
  border-color: darkgrey;
  border-width: 1px;
  border-radius: 5px;
  font-size: 18px;
}

.SelectField > select > option {
  height: 35px;
  width: 80%;
  border-style: solid;
  border-color: darkgrey;
  border-width: 1px;
  border-radius: 5px;
  font-size: 18px;
}

.SelectField > label {
  display: block;
  margin-bottom: 4px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.SelectField > p {
  margin-top: 0px;
  font-size: 14px;
  color: red;
}
