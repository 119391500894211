.TextField {
    padding-left: 25px;
    padding-right: 25px;
}

.TextField > input {
    height: 35px;
    width: 100%;
    border-style: solid;
    border-color: darkgrey;
    border-width: 1px;
    border-radius: 5px;
    font-size: 18px;
    box-sizing: border-box;
}

.TextField > label {
    display: block;
    margin-bottom: 4px;
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
}

.TextField > p {
    margin-top: 0px;
    font-size: 14px;
    color: red;
}