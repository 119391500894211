.LinkButton {
  background: none!important;
  border: none;
  padding: 0!important;
  color: grey;
  font-size: small;
  text-decoration: none;
  cursor: pointer;
  align-self: center;
}

.LinkButton:hover {
    text-decoration: underline;
    color: purple;
}