.Header {
  width: 100%;
  height: 180px;
  background: #e91a2b;
}

.Header > .NavBar {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NavLink {
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
    font-size: 20px;
    color: white;
}

.SelectedNavLink {
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
    font-size: 20px;
    text-decoration: underline;
    color: blue;
}

.Header > .NavBar > span {
  color: white;
  font-size: x-large;
  font-weight: bold;
  width: 200px;
  text-align: center;
}

.Header > .NavBar > img {
  position: absolute;
  left: 30px;
  margin-top: auto;
  margin-bottom: auto;
}

.LoggedHeader > .LoggedNavBar > p {
  color: white;
  font-size: x-large;
  font-weight: bold;
  margin-right: auto;
  margin-left: 20px;
}

.LoggedHeader {
  width: 100%;
  height: 100px;
  background: #e91a2b;
}

.LoggedHeader > .LoggedNavBar {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.LoggedHeader > .LoggedNavBar > img {
  margin-left: 10px;
  justify-self: start;
  height: 70px;
}
